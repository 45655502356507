/**
 * @ID: Footer
 * @author xiefei
 */

import Http from '@/api/userInfo';
import GetKeyword from '@/api/keyword';
import HTTPLogin from '@/api/login'
import HttpSearch from '@/api/search';
import Login from '../Login.vue'
import Register from '../Register.vue'
import Vue from 'vue';
import qs from 'qs';
import Lang from '@/utils/lang';

const DEFAULT_LANG = Lang.getLang(); 
// console.log('DEFAULT_LANG:', DEFAULT_LANG)
let vm = new Vue();

const langMap = {
  en: 'En',
  cn: '中文'
};

export default {
  name: 'Header',

  components: { Login, Register },

  data() {
    return {
      isLogin: false,
      isShowLoginDia: false,
      isShowRegisterDia: false,
      userName: '',
      searchWord: '',
      placeholder: '',
      suggestList: [],
      loading: true,
      lang: langMap[DEFAULT_LANG],
    };
  },

  created() {
    this.getUserData();
    vm.$on('show-login', this.showLogin)
  },

  mounted() {
    GetKeyword.getData().then(res => {
      if (res.code === 0) {
        let data = res.body || {};
        let keyword = data?.info?.keyword;
        
        this.placeholder = keyword ||  this.$t('form.input');
        this.searchWord = keyword;
      }
      
    });
  },

  methods: {
    getUserData() {
      Http.getData({needLogin: false}).then(res => {
        this.loading = false;
        if (res.code === 0) {
          
          let data = res.body || {};
          let userInfo = data?.user_info || {};

          this.isLogin = true;
          this.userName = userInfo.username || '';

          window.localStorage.setItem('STORAGE_USER_INFO', qs.stringify(userInfo))
        } else {
          this.isLogin = false;
        }
      }, () => {
        this.loading = false;
        this.isLogin = false;
      });
    },

    querySearchAsync(name, cb) {
      HttpSearch.getSuggestion({name: name}).then(res => {
        if (res.code === 0) {
          let data = res.body || {};
          
          if (data.list && data.list.length > 0 ) {
            this.suggestList = (data.list).map(item => {
              return {
                value: item.name,
                label: item.name
              }
            });
            cb(data.list.map(item => {
              return {
                name: item.name,
                value: item.name,
              }
            }) || [])
          } else {
            cb([])
          }
        }
      });
    },

    handleSelect({ value }) {
      if (!value.trim()) {
        this.$message({
          message: '请输入搜索关键词',
          type: 'warning'
        });
        return;
      }
      this.handleEnter(value);
    },

    handleEnter(name) {
      name = name.trim();
      
      name && name !== this.$route.query.name && this.$router.push({
        name: 'search',
        query: {
          name: this.searchWord
        }
      });
    },

    logout() {
      HTTPLogin.logout().then((res) => {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: '退出成功',
            duration: '500',
            onClose() {
              window.location.reload();
            }
          });
        }
      });
    },
    
    showLogin() {
      this.isShowLoginDia = true;
    },

    showRegister() {
      this.isShowRegisterDia = true;
    },

    closeDia() {
      this.isShowLoginDia = false;
    },

    closeRegisterDia() {
      this.isShowRegisterDia = false;
    },
    changeLang(val) {
      this.$i18n.locale = val || DEFAULT_LANG;
      this.lang = langMap[val];
      // window.localStorage.setItem('VEDIO_CENTER_LANG', this.$i18n.local);
      Lang.setLang(this.$i18n.locale);
      window.location.reload();
    },
  },


}