import Vue from 'vue';
import Router from 'vue-router';

let Home = () => import('@pages/Home/index.vue');
let Mine = () => import('@pages/Mine/index.vue');
let Search = () => import('@pages/Search/index.vue');
let Channel = () => import('@pages/Channel/index.vue');
let Watch = () => import('@pages/Watch/index.vue');

Vue.use(Router);

const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: "首页"
      }
    },
    {
      path: '/mine',
      name: 'mine',
      component: Mine,
      meta: {
        title: "我的视频"
      }
    },
    {
      path: '/search',
      name: 'search',
      component: Search,
      meta: {
        title: "搜索页"
      }
    },
    {
      path: '/channel',
      name: 'channel',
      component: Channel,
      meta: {
        title: "频道页"
      }
    },
    {
      path: '/watch',
      name: 'watch',
      component: Watch,
      meta: {
        title: "详情页"
      }
    },
  ]
});