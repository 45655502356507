<template>
  <div id="app">
    <Header />
    <div id="container">
      <router-view />
    </div>
    
    <Footer />
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import http from '@utils/http';
import Header from './components/Header/index.vue';
import Footer from './components/Footer/index.vue';
import Vm from '@/utils/eventBus';
import Throttle from 'lodash/throttle';

export default {
  name: 'App',
  components: { Header, Footer },

  created() {
    this.bindScrollBottom();
  },

  mounted() {
    // http.get('/api/index').then((res) => {
    //   console.log('res-----:', res )
    // }, res => {console.log('res', res ) })
  },

  methods: {
    bindScrollBottom() {
      window.addEventListener('scroll', Throttle(() => {
        const scrollY = document.documentElement.scrollTop || document.body.scrollTop // 滚动条在Y轴上的滚动距离
        const vh = document.compatMode === 'CSS1Compat' ? document.documentElement.clientHeight : document.body.clientHeight // 页面的可视高度（能看见的）
        const allHeight = Math.max(document.body.scrollHeight, document.documentElement.scrollHeight) // 页面的总高度（所有的）
        if (scrollY + vh >= allHeight) { // 当滚动条滑到页面底部
          Vm.$emit('page-scroll-bottom');
        }
  
      }), 500)
    },
  }
}
</script>

<style lang="scss" scoped>
#app {
  font-family: FZLTCHPro--GB1-4, FZLTCHPro--GB1, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f9f9f9;
}

#container {
  text-align: left;
  width: $contentWidth;
  margin: 0 auto;
  min-height: 600px;
  margin-top: $headerH;
  padding: 30px 0px;
}
</style>
