/**
 * @ID: Footer
 * @author xiefei
 */
import Lang from '@/utils/lang';

const DEFAULT_LANG = Lang.getLang(); 

export default {
    name: 'Footer',

    data() {
        return {
            lang: DEFAULT_LANG
        };
    },

    methods: {
        changeLang() {
            this.$i18n.locale = this.lang || DEFAULT_LANG;
            // window.localStorage.setItem('VEDIO_CENTER_LANG', this.$i18n.local);
            Lang.setLang(this.$i18n.locale);
        }
    },


}