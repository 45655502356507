const enLocale = require('element-ui/lib/locale/lang/en');

module.exports = {
  ...enLocale,
  login:{
    'title' : 'Video Center',
    'username' : 'User name',
    'password' : 'Password',
    'login' : 'Login',
    'logout': "Logout",
    'register': 'Register',
    'language' : 'Please select language',
    'forgetPwd': 'Forget Password',
    'rememberPwd': 'Remember Password',
    'registerNow': 'Register Now',
    'loginNow': 'Login Now',
    'mail': 'Mail',
    'confirmPwd': 'Confirm Password',
    'ownedAccount': 'Owned Account?',
  },
  form: {
    'input': 'Please Input',
  },
  more: 'More',
  grab: 'Grab',
  grabVideo: 'Grab Video',
  pasteAddress: 'Paste the video address here',
  viewCount: 'views',

  serverBusy: 'Server busy, please wait!',

  myVideo: 'My video',
  filter: 'Filter',
  favorite: 'Favorite',
  favoriting: 'Favoriting',
  download: 'Download',
  watchVideo: 'Watch Video',
  downloading: 'loading',
  deleteVideoTitle: 'Are you sure to delete',

}