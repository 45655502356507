import Vue from 'vue';

Vue.mixin({
  methods: {
    go(id, type) {
      let query = id ? { id } : null;
      let name = type || 'watch';
      let urlId = 'id' in this.$route.query ? this.$route.query.id : '';

      if (id === urlId && name === this.$route.name) {
        return;
      }
      this.$router.push({
        name,
        query,
      })
    },
  },
});