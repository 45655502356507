<template lang="pug" >
  .login-wrap
    el-dialog(
      :title="$t('login.register')",
      :visible.sync="isShowLayer",
      width="428px",
      append-to-body
      custom-class="login-dia-register",
      @close="handleClose"
    )
      .login-content-wrap
        .content
          el-form(label-position="top")
            el-form-item(label="")
              el-input(v-model="email", size="large", :placeholder="$t('login.mail')")
            el-form-item(label="")
              el-input(v-model="username", size="large", :placeholder="$t('login.username')")
            el-form-item(label="")
              el-input(v-model="password", size="large" show-password,  :placeholder="$t('login.password')")
            el-form-item(label="")
              el-input(v-model="confirm_password", size="large" show-password, :placeholder="$t('login.confirmPwd')")
          .login-btn-wrap
            el-button.login-btn(size="large", type="primary", @click="register", :placeholder="$t('login.username')") {{$t('login.register')}}
          .space-between.tail
            span {{$t('login.ownedAccount')}}
            el-button.forget-pwd(type="text", @click="goLogin") {{$t('login.login')}}
</template>
<script >
import Http from '@/api/login'

export default {
  name: 'Register',
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      email: '',
      confirm_password: '',
      username: '',
      password: '',
      isShowLayer: this.isShow,
    };
  },

  mounted() {
  },

  methods: {
    goLogin() {
      
      this.$emit('show-login')
      this.$nextTick(() => {
        this.isShowLayer = false;
      })
      
    },

    register() {
      let params = {
        username: this.username,
        password: this.password,
        email: this.email,
        confirm_password: this.confirm_password,
        phone: '',
        token: '',
      };
      if (this.password !== this.confirm_password) {
        this.$message({
          type: 'warning',
          message: '您输入的密码不一致！'
        })
       }
      Http.register(params).then(res => {
        if (res.code === 0) {
          this.goLogin();
          setTimeout(() => {
            this.$message({
              type: 'success',
              message: res.message || '注册成功'
            });
          }, 1000)
          
        }
      });
    },

    handleClose() {
      this.$emit('close-reg-dia');
    },
  },

  watch: {
    isShow(val) {
      this.isShowLayer = val;
    }
  }
}
</script>
<style lang="scss" scoped >
  .login-dia-register .login-content-wrap .content {
    margin: 0 auto;
    .forget-pwd {
      font-size: 12px;
      font-weight: 400;
    }
    .login-btn-wrap {
      text-align: center;
      .login-btn {
        margin: 20px 0;
        
      }
    }
    .tail {
      width: 60%;
      margin: 0 auto;
      font-size: 12px;
      font-weight: 400;
    }
  }
</style>
<style lang="scss">
  .login-dia-register {
    .el-dialog__header {
      text-align: center;
      font-size: 18px;
      font-weight: 700;
    }
  }
  .login-dia-register .login-content-wrap .content {
    padding: 0 20px;
    .el-input {
      width: 100%;
    }
    .el-form--label-top .el-form-item__label {
      padding: 0;
    }
    .el-checkbox__label {
      font-weight: 400;
      font-size: 12px;
    }
    
    .el-button--large {
      padding: 12px 50px;
      width: 100%;
      background-color: #E30000;
      border-color: #E30000;
    }
  }
</style>