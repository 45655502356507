import Vue from 'vue'
import App from './App.vue'
import router from './router/'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@assets/scss/global.scss' 
import './mixin'
import i18n from './tools/i18n'

Vue.use(ElementUI, { size: 'small', zIndex: 3000 });
Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
})

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
