import Http from '@/utils/http';
import qs from 'qs';

export default {
    getData(params, option) {
        return Http.post(`/api/search`, qs.stringify(params), option);
    },

    getSuggestion(params, option) {
        return Http.get('/api/search/tips', { params }, option);
    }
};