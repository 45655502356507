  /* 国际化zh_cn */
  // import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
  const zhLocale = require('element-ui/lib/locale/lang/zh-CN')
  // console.log(zhLocale)
  
  module.exports = {
    ...zhLocale,
    login:{
        'title' : '视频中心',
        'username' : '用户名',
        'password' : '密码',
        'login' : '登录',
        'logout': "退出",
        'register': '注册',
        'language' : '请选择语言',
        'forgetPwd': '忘记密码',
        'rememberPwd': '记住密码',
        'registerNow': '立即注册',
        'loginNow': '立即登录',
        'mail': '邮箱',
        'confirmPwd': '确认密码',
        'ownedAccount': '已拥有账号？',
    },
    form: {
      'input': '请输入内容',
    },
    more: '更多',
    grab: '抓取',
    grabVideo: '抓取视频',
    pasteAddress: '将视频地址粘贴到这里',
    viewCount: '次观看',

    serverBusy: '服务器繁忙，请稍后！',

    myVideo: '我的视频',
    filter: '筛选',
    favorite: '收藏',
    favoriting: '收藏中',
    download: '下载',
    watchVideo: '看视频',
    downloading: '下载中',
    deleteVideoTitle: '你确认要删除此视频吗?',
    
}