import Cookie from 'browser-cookies';
const DEFAULT_LANG = 'cn';
const LANG_KEY = 'VEDIO_CENTER_LANG';

export default {
  getLang() {
    return Cookie.get(LANG_KEY) || DEFAULT_LANG;
  },

  setLang(lang = DEFAULT_LANG) {
    Cookie.set(LANG_KEY, lang);
    // document.cookie += `lang=${lang}`;
  }
}