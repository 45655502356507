import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Lang from '@/utils/lang'
import ElementLocale from 'element-ui/lib/locale'

Vue.use(VueI18n)

const messages = {
  'cn': require('../assets/i18n/zh'),   // 中文语言包
  'en': require('../assets/i18n/en')    // 英文语言包
}

// const defaultLocale = window.localStorage.getItem('VEDIO_CENTER_LANG') || 'cn';


let i18n = new VueI18n({
  locale : Lang.getLang(), // set locale 默认显示中文
  messages : messages // set locale messages
})

ElementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n;